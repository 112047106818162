import React from 'react';

const Contact = () => {
  return (
    <section id="contact">
      <h2>Contact</h2>
      <ul className="contact-links">
          <li><a href="https://www.linkedin.com/in/benjamin-danneg%C3%A5rd-b96b60196/"><h1>Linkedin</h1></a></li>
          <li><a href="mailto:benjamindannegard@gmail.com"><h1>Email</h1></a></li>
      </ul>
    </section>
  );
};

export default Contact;
