import React from 'react';

const Introduction = () => {
  return (
    <section id="about">
      <p class="big-text-block">I'm Benjamin Dannegård
      currently working as a Product Experience Designer at Arduino in Malmö, Sweden</p>
    </section>
  );
};

export default Introduction;
