import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navigation from "./components/navigation";
import Introduction from "./components/introduction";
import ProjectList from "./components/projectlist";
import Skills from "./components/skills";
import Contact from "./components/contact";
import PlugAndMakePage from "./components/plugandmakepage";
import ProjectHubPage from "./components/projecthubpage"
import "./index.css";  // Import global styles

const App = () => {
  return (
    <Router>
      <Navigation />
      <main>
        <Routes>
          <Route path="/" element={<>
            <Introduction />
            <ProjectList />
            <Skills />
            <Contact />
          </>} />
          <Route path="/" element={<ProjectList />} />
          <Route path="/plug-and-make" element={<PlugAndMakePage />} />
          <Route path="/project-hub" element={<ProjectHubPage />} />
        </Routes>
      </main>
    </Router>
  );
};

export default App;
