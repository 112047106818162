import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import plugAndMakeProcess from "../assets/images/plug-and-make-design-process.jpeg";
import plugAndMakeAssets from "../assets/images/plug-and-make-asset-design.png";



const PlugAndMakePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when the component mounts
  }, []);

  return (
    <div>
      <h4>Plug and Make kit product design</h4>
        <p className="projText">
          The Plug and Make kit was conceptualized as a kit for use in education and by embedded tech enthusiasts. The idea was to give users an easy plug and play kind of experience, while teaching them the cornerstones of Arduino.
        </p> 

        <p className="projText">
          I was part of the design process to decide what projects should be developed and included as examples for users buying the kit. While also designing the unboxing expereince, deciding what the user should be met with when they first recieve and open their product.
        </p>

        <figure>
        <img src={plugAndMakeProcess} alt="Plug and Make Kit" className="inline-img" />
        </figure>

        <p className="projText">
          We decided on a few projects that would be fun, educational, and something the user would want to keep on their desk or in their home. After several design meetings to decide on the final projects we also decided on the visual language of the digital content.
        </p>

        <figure>
        <img src={plugAndMakeAssets} alt="Plug and Make Kit" className="inline-img-bigger" />
        </figure>

        <p className="projText">
          I took part in developing a few of the projects when the projects, guidelines for content and visual language were decided on. When developing the projects I had to plan out the user experience and how to best teach the different concepts that would be included in the example. Then designing visual assets, developing the code for the example, and writing the text was next in the process.
        </p>

        <p className="projText">
          The next step was to user test the experience and examples. I and two coworkers shared the responsibility of user testing the product. We would take turns hosting a user testing session in our office. In these sessions we would do an interview to find out some initial information about the user, then let them test the experience from unboxing to following the examples. Then at the end, we would hold a conclusion interview to get some final thoughts. Then from the observations and data gathered, we would create actionable tasks and address them. We would continuously improve the product design with the feedback from users and internal testing.
        </p>
        
        <p className="projText">
          One of the bigger challenges was explaining the use of the Arduino IoT Cloud, which was integral in the examples, to new users while also teaching them the hardware parts. We focused on the user experience of teaching someone completely new the uses of the Cloud platform. With all our design effort and thorough user testing the Plug and Make kit became one of the best products released in recent times for the company.
        </p>
    </div>
  );
};

export default PlugAndMakePage;
