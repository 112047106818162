import React from 'react';
import { Link } from "react-router-dom";
import plugAndMakeImg from "../assets/images/plug-and-make-thumbnail.jpg";
import projectHubImage from "../assets/images/project-hub-thumbnail.png";

const projects = [
  {
    id: "plug-and-make",
    title: "Plug and Make kit product design",
    description: "Designing the plug and make kits user experience.",
    image: plugAndMakeImg,
  },
  {
    id: "project-hub",
    title: "Managing Project Hub",
    description: "Managing and redesigning the platform to fit our ecosystem.",
    image: projectHubImage,
  },
];

const ProjectList = () => {
  return (
    <section id="projects">
      <h2>Previous Work</h2>
      {projects.map((project, index) => (
        <div key={index} className="project">
          <div className="project-content">
            <h3>{project.title}</h3>
            <p className="listP">{project.description}</p>
            <Link to={`/${project.id}`} className="readLink">Read More</Link>
            </div>
            <img src={project.image} className="project-img" />
        </div>
      ))}
    </section>
  );
};

export default ProjectList;
