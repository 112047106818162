import React from 'react';

const skills = [
  'UX design',
  'Interaction design',
  'Prototyping',
  'User research',
  'Technical Writing',
  'Content Design',
  'Product Design',
  'Wireframing',
  'Adobe Suite',
  'Figma',
  'c#',
  'Python', 
  'c++', 
  'HTML',
  'React',
  'css',
  'Javascript',
];

const Skills = () => {
  return (
    <section id="skills">
      <h5>Skills</h5>
      <ul className="skillList">
        {skills.map((skill, index) => (
          <li key={index}>{skill}</li>
        ))}
      </ul>
    </section>
  );
};

export default Skills;
