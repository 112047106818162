import React, { useState } from "react";

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div id="Top" className="navigation w-nav" role="banner">
      <nav className="nav-container">
        <a href="https://www.dannegard.com"><h1 className="logo">Benjamin Dannegård</h1></a>
        <ul className="nav-links">
          <li><a href="https://www.linkedin.com/in/benjamin-danneg%C3%A5rd-b96b60196/"><h1>Linkedin</h1></a></li>
          <li><a href="mailto:benjamindannegard@gmail.com"><h1>Email</h1></a></li>
        </ul>
      </nav>
    </div>
  );
};

export default Navigation;
