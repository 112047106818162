import React, { useEffect } from "react";
import { useParams } from "react-router-dom";


const ProjectHubPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when the component mounts
  }, []);

  return (
    <div>
      <h4>Managing Project Hub</h4>
        <p className="projText">
          Project Hub is a web platform where users can share projects created with Arduino products.
        </p>

        <p className="projText">
          I have been managing this platform for four years since I started at Arduino. My responsibilities include reviewing projects that are to be published on the site, managing competitions, collecting and editing content for external stakeholders, helping out with support requests, and managing promotional content.
        </p>

        <p className="projText">
          When I started at Arduino Project Hub was hosted by another platform, Hackster. But we have since migrated from Hackster and made Project Hub entirely our platform. This came with a lot of redesign and content migration work. The entire platform had to be re-made with features similar to those that existed before. I helped with the content migration, making sure nothing was lost when we moved to the new site. I also helped out with the design of the new platform. Making wireframes and suggestions as to how the website should look and what pages we needed to have at launch.
        </p>

        <p className="projText">
          After launch, I created a roadmap of what features should be developed next and their priorities. I then worked with the development team and managers to finalize the roadmap. I created wireframes to demonstrate what the new features should be and to give the development team a better idea of what was needed.
        </p>

        <p className="projText">
          The day to day of managing project hub is mostly reviewing projects and helping with user support. But over the years I have had a large part in making Project Hub the platform it is today, which I am proud of.
        </p>
    </div>
  );
};

export default ProjectHubPage;
